<template>
  <div>
    <nf-breadcrumb :breadList="[{ name: '专家人才', path: '/expertTalent' }, { name: '专家人才详情' }]"></nf-breadcrumb>
    <div class="message">
      <div class="synopsis">
        <div class="imgWrap">
          <el-image
                  style="width: 150px; height: 150px"
                  :src="detail.photo"
                  fit="contain"></el-image>
        </div>
        <div class="info">
          <div class="name">
            {{ detail.name }}
            <span class="baseName" v-if="detail.baseName">{{ detail.baseName }}</span>
            <span class="baseName" v-else>暂无基地</span>
          </div>
          <el-row>
            <el-col :span="12">职务：{{ detail.technicalTitle }}</el-col>
            <el-col :span="12">专家领域：{{ detail.professionalism }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="12">电话：{{detail.phoneNumber}}</el-col>
            <el-col :span="12">工作单位：{{ detail.workUnit }}</el-col>
          </el-row>
          <div class="consult" @click="$router.push('/download')">我要咨询</div>
        </div>
      </div>
      <div class="content">
        <div class="content_title">
          <div class="title_text">基本信息</div>
        </div>
        <div class="content_text">{{ detail.content }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import nfBreadcrumb from '@/component/breadcrumb/breadcrumb';
import Vue from 'vue';
import { Image } from 'element-ui';
Vue.use(Image);
export default {
  name: 'expertTalentDetail',
  data() {
    return {
      detail: {} // 专家人才信息详情
    };
  },
  methods: {
    async getDetail() {
      const id = this.$route.query.id;
      const api = this.$fetchApi.expertTalentDetail.api + id;
      const data = await this.$fetchData.fetchPost({}, api, 'json');
      if (data.code === '200' && data.result) {
        let detail = data.result;
        detail.phoneNumber = this.phoneHandle(detail.phoneNumber);

        this.detail = detail;
      }
    },
    phoneHandle(num){
      return num.substring(0, 3) + '****' + num.substring(num.length - 4)
    }
  },
  components: {
    nfBreadcrumb
  },
  created() {
    this.getDetail();
  }
};
</script>

<style lang="less" scoped>
.message {
  width: 1200px;
  margin: 20px auto;
}
.synopsis {
  overflow: hidden;
  padding-bottom: 30px;
  .imgWrap {
    float: left;
    width: 150px;
    height: 150px;
    margin-right: 20px;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .info {
    overflow: hidden;
    .name {
      font-size: 24px;
      padding-bottom: 16px;
      .baseName {
        color: #009944;
        vertical-align: middle;
        border: 1px solid #009944;
        border-radius: 3px;
        font-size: 14px;
        padding: 5px;
        margin-left: 20px;
      }
    }
    .el-col {
      color: #666;
      font-size: 16px;
      padding-bottom: 16px;
    }
    .consult {
      background: #265ae1;
      width: 114px;
      height: 36px;
      text-align: center;
      line-height: 36px;
      font-size: 16px;
      color: #fff;
    }
  }
}
.content {
  .content_title {
    height: 38px;
    background: #f5f5f5;
    .title_text {
      height: 100%;
      width: 200px;
      text-align: center;
      line-height: 38px;
      font-size: 16px;
      color: #448afc;
      background: #fff;
      border-top: 2px solid #448afc;
    }
  }
  .content_text {
    font-size: 20px;
    color: #777;
    padding-top: 20px;
  }
}
</style>
